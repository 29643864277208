#ftco-loader_preloader {
  position: fixed;
  width: 96px;
  height: 96px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  -o-transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  transition: opacity 0.2s ease-out, visibility 0s linear 0.2s;
  z-index: 1000;
}

#ftco-loader_preloader.fullscreen_preloader {
  padding: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  background-color: #eeeeee;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#ftco-loader_preloader.show_preloader {
  -webkit-transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  -o-transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  transition: opacity 0.4s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

#ftco-loader_preloader .circular_preloader {
  -webkit-animation: loader-rotate 2s linear infinite;
  animation: loader-rotate 2s linear infinite;
  position: absolute;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
  display: block;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

#ftco-loader_preloader .path_preloader {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: loader-dash 1.5s ease-in-out infinite;
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}

@-webkit-keyframes loader-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader-rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136px;
  }
}

@keyframes loader-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -136px;
  }
}

svg .ball_preloader {
  -webkit-animation: hideshow 2.8s linear infinite;
  animation: hideshow 2.8s linear infinite;
}

svg .right_paddle_preloader {
  -webkit-animation: wiggle 2.8s linear infinite;
  animation: wiggle 2.8s linear infinite;
}

svg .left_paddle_preloader {
  -webkit-animation: wiggletwo 2.8s linear infinite;
  animation: wiggletwo 2.8s linear infinite;
}

svg {
  -webkit-transform: translate(0, 900) !important;
  transform: translate(0, 900) !important;
}

g {
  -webkit-transform: translate(0, 900) !important;
}

@-webkit-keyframes wiggle {
  0% {
    transform: translate(790px, -60px);
  }

  10% {
    transform: translate(770px, -65px);
  }

  20% {
    transform: translate(745px, -62px);
  }

  30% {
    transform: translate(824px, -68px);
  }

  40% {
    transform: translate(770px, -60px);
  }

  50% {
    transform: translate(790px, -107px);
  }

  60% {
    transform: translate(770px, -87px);
  }

  70% {
    transform: translate(810px, -77px);
  }

  80% {
    transform: translate(790px, -97px);
  }

  90% {
    transform: translate(720px, -77px);
  }

  100% {
    transform: translate(790px, -60px);
  }
}

@keyframes wiggle {
  0% {
    transform: translate(790px, -60px);
  }

  10% {
    transform: translate(770px, -65px);
  }

  20% {
    transform: translate(745px, -62px);
  }

  30% {
    transform: translate(824px, -68px);
  }

  40% {
    transform: translate(770px, -60px);
  }

  50% {
    transform: translate(790px, -107px);
  }

  60% {
    transform: translate(770px, -87px);
  }

  70% {
    transform: translate(810px, -77px);
  }

  80% {
    transform: translate(790px, -97px);
  }

  90% {
    transform: translate(720px, -77px);
  }

  100% {
    transform: translate(790px, -60px);
  }
}

@-webkit-keyframes wiggletwo {
  0% {
    transform: translate(0px, -100px);
  }

  10% {
    transform: translate(10px, -70px);
  }

  20% {
    transform: translate(15px, -90px);
  }

  30% {
    transform: translate(5px, -100px);
  }

  40% {
    transform: translate(10px, -60px);
  }

  50% {
    transform: translate(45px, -50px);
  }

  60% {
    transform: translate(0px, -100px);
  }

  70% {
    transform: translate(20px, -20px);
  }

  80% {
    transform: translate(40px, -80px);
  }

  90% {
    transform: translate(15px, -60px);
  }

  100% {
    transform: translate(0px, -100px);
  }
}

@keyframes wiggletwo {
  0% {
    transform: translate(0px, -100px);
  }

  10% {
    transform: translate(10px, -70px);
  }

  20% {
    transform: translate(15px, -90px);
  }

  30% {
    transform: translate(5px, -100px);
  }

  40% {
    transform: translate(10px, -60px);
  }

  50% {
    transform: translate(45px, -50px);
  }

  60% {
    transform: translate(0px, -100px);
  }

  70% {
    transform: translate(20px, -20px);
  }

  80% {
    transform: translate(40px, -80px);
  }

  90% {
    transform: translate(15px, -60px);
  }

  100% {
    transform: translate(0px, -100px);
  }
}

@-webkit-keyframes hideshow {
  0% {
    transform: translate(0px, -40px);
  }

  10% {
    transform: translate(230px, 100px);
  }

  20% {
    transform: translate(390px, -80px);
  }

  30% {
    transform: translateY(450px, -86px);
  }

  32% {
    transform: translateY(450px, -83px);
  }

  40% {
    transform: translate(650px, 90px);
  }

  50% {
    transform: translate(750px, -40px);
  }

  60% {
    transform: translate(600px, 100px);
  }

  70% {
    transform: translateY(495px, -90px);
  }

  80% {
    transform: translate(330px, -30px);
  }

  90% {
    transform: translate(200px, 100px);
  }

  100% {
    transform: translate(0px, -40px);
  }
}

@keyframes hideshow {
  0% {
    transform: translate(0px, -40px);
  }

  10% {
    transform: translate(230px, 100px);
  }

  20% {
    transform: translate(420px, -80px);
  }

  30% {
    transform: translateY(450px, -86px);
  }

  32% {
    transform: translateY(450px, -83px);
  }

  40% {
    transform: translate(650px, 90px);
  }

  50% {
    transform: translate(750px, -40px);
  }

  60% {
    transform: translate(600px, 100px);
  }

  70% {
    transform: translateY(495px, -90px);
  }

  80% {
    transform: translate(330px, -30px);
  }

  90% {
    transform: translate(200px, 100px);
  }

  100% {
    transform: translate(0px, -40px);
  }
}

#itall {
  transform: translate(0px, 200px);
  -webkit-transform: translate(0px, 200px);
}



#Layer_1 {
  left: 16vw;
}

@media only screen and (min-width: 320px) {
  #Layer_1 {
    left: 20vw;
  }
}
@media only screen and (min-width: 375px) {
  #Layer_1 {
    left: 24vw;
  }
}
@media only screen and (min-width: 425px) {
  #Layer_1 {
    left: 28vw;
  }
}
@media only screen and (min-width: 525px) {
  #Layer_1 {
    left: 32vw;
  }
}
@media only screen and (min-width: 625px) {
  #Layer_1 {
    left: 34vw;
  }
}
@media only screen and (min-width: 768px) {
  #Layer_1 {
    left: 38vw;
  }
}
@media only screen and (min-width: 1024px) {
  #Layer_1 {
    left: 40vw;
  }
}
@media only screen and (min-width: 1600px) {
  #Layer_1 {
    left: 42vw;
  }
}
